import React from "react";
import { FaqList } from "../helper/Helper";
import FaqQustion from "../common/FaqQustion";

function Faq() {
  return (
    <>
      <div id="Faq" className="py-20 lg:py-32  bg-white">
        <div className="max-w-[1530px] mx-auto px-4 sm:px-6 lg:px-8 2xl:px-10 xl:pt-6">
          <h1 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-[80px]  text-[#303030] text-center capitalize mb-6 md:mb-12">
            frequently asked questions
          </h1>
          <div className="faq-bg p-4 sm:p-6 lg:p-10 xl:p-16">
            {FaqList.map((faq, index) => (
              <FaqQustion key={index} question={faq.qustion} answer={faq.ans} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
