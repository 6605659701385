import React from "react";
import { sliderData } from "../helper/Helper";

function Slider({ className }) {
  return (
    <>
      <div className="h-[5px] bg-[#360700]"></div>
      <div className="h-3 md:h-5 lg:h-6 w-full bg-[#FAA501]"></div>
      <div className="h-1 md:h-[7px] w-full bg-[#FE5500]"></div>
      <div className="h-1 md:h-[7px] w-full bg-[#FCD52D]"></div>
      <div className="py-3 md:py-5 xl:py-6 ">
        <marquee behavior="" direction="left">
          <div className="flex gap-4 sm:gap-6 xl:gap-8 items-center">
            {sliderData.map((item, index) => (
              <img
                key={index}
                src={item.path}
                alt="img"
                className="max-w-[70px] md:max-w-[100px] xl:max-w-[128px]"
              />
            ))}
          </div>
        </marquee>
      </div>
      <div className="h-0.5 w-full bg-[#360700]"></div>
    </>
  );
}

export default Slider;
