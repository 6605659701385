import React from "react";
import footerLeft from "./../assets/img/png/footer-left.png";
import { TeligramIcon, TwitterIcon } from "../helper/Icon";
import solonImg from "./../assets/img/svg/solon.svg";
import footerRight from "./../assets/img/png/footer-right.png";
function Footer() {
  return (
    <>
      <div className="h-[5px] bg-[#360700]"></div>
      <div className="h-3 md:h-5 lg:h-7 w-full bg-[#FAA501]"></div>
      <div className="h-1 md:h-[7px] w-full bg-[#FE5500]"></div>
      <div className="h-1 md:h-[7px] w-full bg-[#FCD52D]"></div>
      <div className="h-5 lg:h-7 w-full bg-[#F8EABA]"></div>
      <footer>
        <div className="flex flex-col-reverse lg:flex-row relative  items-center bg-[#FAC150]">
          <div className="absolute bottom-0 right-0 max-w-[100px] sm:max-w-[140px] md:max-w-[240px]">
            <img src={footerRight} alt="img" className="w-full" />
          </div>
          <div className="max-w-[260px] sm:max-w-[300px] lg:max-w-[500px] xl:max-w-[700px] mr-auto lg:mx-0">
            <img src={footerLeft} alt="img" className="w-full" />
          </div>
          <div className="max-w-[566px] mx-auto py-12 sm:py-16 xl:py-10 lg:mb-52 xl:mb-0">
            <div>
              <h2 className="text-4xl md:text-5xl lg:text-6xl 2xl:text-[80px]  text-[#303030]  capitalize">
                Socials
              </h2>
              <p className="text-base sm:text-lg xl:text-[24px] text-[#393939] uppercase my-4 md:my-6 pb-3">
                Join the $moomoo community
              </p>{" "}
              <div className="flex items-center lg:pt-4   gap-3 sm:gap-5 lg:gap-7 xl:gap-8">
                <a
                  href={""}
                  className="py-2.5 md:py-4 px-5 md:px-7 rounded-lg transition-all duration-300 ease-in-out border-2 bg-[#FDC238] border-[#1A1A1A] navshadow    text-base text-incon lg:text-2xl xl:text-3xl  font-bold rotate-[-1.5deg] hover:shadow-[4.659px_4.659px_20px_0px_#00000091] "
                >
                  BUY $MOOMOO
                </a>
                <a
                  href=""
                  className="w-12 md:w-14 lg:w-[70px] h-12 md:h-14 lg:h-[70px] rounded-2xl border border-black shadow-[4.659px_4.659px_0px_0px_#000] flex flex-col justify-center  items-center bg-[#FBF492] transition-all duration-300 ease-in-out hover:shadow-[4.659px_4.659px_20px_0px_#00000091] text-[#00000091]"
                >
                  <TwitterIcon />
                </a>{" "}
                <a
                  href=""
                  className="w-12 md:w-14 lg:w-[70px] h-12 md:h-14 lg:h-[70px] rounded-2xl border border-black shadow-[4.659px_4.659px_0px_0px_#000] flex flex-col justify-center  items-center bg-[#FBF492] transition-all duration-300 ease-in-out hover:shadow-[4.659px_4.659px_20px_0px_#00000091] text-[#00000091] rotate-[5deg]"
                >
                  <TeligramIcon />
                </a>
                <a
                  href=""
                  className="w-12 md:w-14 lg:w-[70px] h-12 md:h-14 lg:h-[70px] rounded-2xl border border-black shadow-[4.659px_4.659px_0px_0px_#000] flex flex-col justify-center  items-center bg-[#FBF492] transition-all duration-300 ease-in-out hover:shadow-[4.659px_4.659px_20px_0px_#00000091] text-[#00000091]"
                >
                  <img
                    src={solonImg}
                    alt="solonImg"
                    className="w-[20px] lg:w-fit h-auto "
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
