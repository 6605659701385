import sliderImg from "./../assets/img/svg/slider-img.svg";
export const headerLinks = [
  { name: "Home", link: "#home" },
  { name: "How to Buy", link: "#HowtoBuy" },
  { name: "Tokonomics", link: "#Tokonomics" },
  { name: "Faq", link: "#Faq" },
];
export const sliderData = [
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
  { path: sliderImg },
];
export const FaqList = [
  {
    qustion: "how is moomoo?",
    ans: "moomo is a cartoon Hedgehog on a mission to win Hegena. Hege and associated characters were created as representations of stereotypes in modern societies.",
  },
  {
    qustion: "what chain is moomoo on?",
    ans: "$moomoo is available to trade on the Solana blockchain. Please see the 'How to buy $moomoo' section to learn more.",
  },
  {
    qustion: "how can i buy $ moomoo?",
    ans: "Currently, moomo is only available for trading on-chain. Look out for future announcements to see if we get a listing on your favourite exchange!",
  },
  {
    qustion: "what is the price prediction?",
    ans: "$moomo is available to trade on the Solana blockchain. Please see the 'How to buy $moomoo' section to learn more.",
  },
];
