import React from "react";
import { CrossIcon } from "../helper/Icon";
import { headerLinks } from "../helper/Helper";

const MobileHeader = ({ toggleOpen }) => {
  return (
    <>
      <div className="h-screen w-screen lg:hidden flex items-center justify-center flex-col relative">
        <button className="absolute top-10 right-4" onClick={toggleOpen}>
          <CrossIcon />
        </button>

        <div className=" flex flex-col items-center gap-8">
          {headerLinks.map((item, index) => (
            <a
              key={index}
              href={item.link}
              onClick={toggleOpen}
              className="text-white text-lg transition-all duration-300 ease-in-out hover:text-black]"
            >
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default MobileHeader;
