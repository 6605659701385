import React from "react";
import bom from "./../assets/img/svg/bom.svg";
import coin from "./../assets/img/svg/coin.svg";
import paal from "./../assets/img/svg/paal.svg";
import coingecko from "./../assets/img/svg/coingecko.svg";
import dext from "./../assets/img/svg/dext.svg";
import bitmart from "./../assets/img/svg/bitmart.svg";
import toobit from "./../assets/img/svg/toobit.svg";
import superex from "./../assets/img/svg/superex.svg";
import indoex from "./../assets/img/svg/indoex.svg";
function OurPartners() {
  return (
    <>
      <div className="relative py-16 md:py-20 xl:py-24 overflow-hidden bg-[#FDC238]">
        <div className="absolute bottom-7 -left-3">
          <img
            src={bom}
            alt=""
            className="max-w-[50px] sm:max-w-20  lg:max-w-[122px] w-full"
          />
        </div>{" "}
        <div className="absolute top-7 right-0">
          <img
            src={bom}
            alt=""
            className="max-w-[50px] sm:max-w-20  lg:max-w-[122px] w-full"
          />
        </div>
        <div className="max-w-[1130px] mx-auto px-4 sm:px-6 lg:px-8 2xl:px-10 relative z-10">
          <h1 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-[80px]  text-[#303030] text-center">
            Our Partners
          </h1>
          <div className="flex flex-wrap  items-center gap-3 md:gap-4 pt-6 lg:pt-8 xl:pt-14 justify-center">
            <a
              href="/"
              className="max-w-[150px] md:max-w-[200px]  xl:max-w-[250px] w-full  "
            >
              <img src={coin} alt="icon" className="w-full" />
            </a>{" "}
            <a
              href="/"
              className="max-w-[150px] md:max-w-[200px]  xl:max-w-[250px] w-full  "
            >
              <img src={paal} alt="icon" className="w-full" />
            </a>{" "}
            <a
              href="/"
              className="max-w-[150px] md:max-w-[200px]  xl:max-w-[250px] w-full  "
            >
              <img src={coingecko} alt="icon" className="w-full" />
            </a>{" "}
            <a
              href="/"
              className="max-w-[150px] md:max-w-[200px]  xl:max-w-[250px] w-full  "
            >
              <img src={dext} alt="icon" className="w-full" />
            </a>{" "}
            <a
              href="/"
              className="max-w-[150px] md:max-w-[200px]  xl:max-w-[250px] w-full  "
            >
              <img src={bitmart} alt="icon" className="w-full" />
            </a>{" "}
            <a
              href="/"
              className="max-w-[150px] md:max-w-[200px]  xl:max-w-[250px] w-full  "
            >
              <img src={toobit} alt="icon" className="w-full" />
            </a>{" "}
            <a
              href="/"
              className="max-w-[150px] md:max-w-[200px]  xl:max-w-[250px] w-full "
            >
              <img src={superex} alt="icon" className="w-full" />
            </a>{" "}
            <a
              href="/"
              className="max-w-[150px] md:max-w-[200px]  xl:max-w-[250px] w-full "
            >
              <img src={indoex} alt="icon" className="w-full" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurPartners;
