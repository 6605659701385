import React from "react";
import { TeligramIcon, TwitterIcon } from "../helper/Icon";
import solonImg from "./../assets/img/svg/solon.svg";
import heroImg from "./../assets/img/png/hero.png";
function Hero() {
  return (
    <>
      <div id="home" className="relative">
        <div className="max-w-[1340px] mx-auto px-4  sm:px-6 lg:px-8 2xl:px-10">
          <div className="flex flex-col gap-7"></div>
          <div className="max-w-[891px] flex flex-col mt-32 sm:mt-40 md:mt-60  xl:mt-80 pb-6 md:pb-20 ">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl 2xl:text-[100px] font-bold text-passion heading-shadow text-[#FE5500] uppercase">
              Join $MOOMOO
            </h1>
            <h1 className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl 2xl:text-[100px] font-bold text-passion heading-shadow text-white uppercase my-2">
              Ethereum network.
            </h1>
            <p className="text-[#393939] tracking-[0.25px] leading-[150%] text-lg sm:text-xl xl:text-2xl md:max-w-[450px] xl:max-w-[695px] my-2 sm:my-4 lg:mt-6">
              MOOMOO with his fiery personality, seeks to take over the Ethereum
              network.
            </p>
            <div className="flex items-center pt-6 lg:pt-8 xl:pt-12 gap-3 sm:gap-5 lg:gap-7 xl:gap-8">
              <a
                href={""}
                className="py-2.5 md:py-4 px-5 md:px-7 rounded-lg transition-all duration-300 ease-in-out border-2 bg-[#FDC238] border-[#1A1A1A] navshadow    text-base text-incon lg:text-2xl xl:text-3xl  font-bold rotate-[-1.5deg] hover:shadow-[4.659px_4.659px_20px_0px_#00000091] "
              >
                BUY $MOOMOO
              </a>
              <a
                href=""
                className="w-12 md:w-14 lg:w-[70px] h-12 md:h-14 lg:h-[70px] rounded-2xl border border-black shadow-[4.659px_4.659px_0px_0px_#000] flex flex-col justify-center  items-center bg-[#FBF492] transition-all duration-300 ease-in-out hover:shadow-[4.659px_4.659px_20px_0px_#00000091] text-[#00000091]"
              >
                <TwitterIcon />
              </a>{" "}
              <a
                href=""
                className="w-12 md:w-14 lg:w-[70px] h-12 md:h-14 lg:h-[70px] rounded-2xl border border-black shadow-[4.659px_4.659px_0px_0px_#000] flex flex-col justify-center  items-center bg-[#FBF492] transition-all duration-300 ease-in-out hover:shadow-[4.659px_4.659px_20px_0px_#00000091] text-[#00000091] rotate-[5deg]"
              >
                <TeligramIcon />
              </a>
              <a
                href=""
                className="w-12 md:w-14 lg:w-[70px] h-12 md:h-14 lg:h-[70px] rounded-2xl border border-black shadow-[4.659px_4.659px_0px_0px_#000] flex flex-col justify-center  items-center bg-[#FBF492] transition-all duration-300 ease-in-out hover:shadow-[4.659px_4.659px_20px_0px_#00000091] text-[#00000091]"
              >
                <img
                  src={solonImg}
                  alt="solonImg"
                  className="w-[20px] lg:w-fit h-auto "
                />
              </a>
            </div>
          </div>
          <div className="max-w-[400px] lg:max-w-[500px] xl:max-w-[646px] md:absolute right-0 bottom-0 ms-auto md:mx-0  -me-4  sm:-me-6">
            <img src={heroImg} alt="heroImg" className="w-full h-auto" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
