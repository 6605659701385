import logo from "./logo.svg";
import "./App.css";
import Header from "./components/common/Header";
import Hero from "./components/home/Hero";
import Slider from "./components/common/Slider";
import circel from "./../src/components/assets/img/png/circel.png";
import OurPartners from "./components/home/OurPartners";
import WhatMooMoo from "./components/home/WhatMooMoo";
import HowTobuy from "./components/home/HowTobuy";
import Tokenomics from "./components/home/Tokenomics";
import Faq from "./components/home/Faq";
import Footer from "./components/common/Footer";
function App() {
  return (
    <>
      <div className="relative overflow-hidden ">
        <div className="absolute right-0 bottom-0 md:bottom-auto md:top-0 z-[-1] max-w-[700px] lg:max-w-[900px]  2xl:max-w-[1200px] ">
          <img src={circel} alt="icon" className="w-full" />
        </div>
        <Header />
        <Hero />
      </div>
      <div className="bg-[#F8EABA]">
        <Slider />
      </div>

      <OurPartners />
      <WhatMooMoo />
      <HowTobuy />
      <Tokenomics />
      <Faq />
      <Footer />
      <div className="bg-white">
        <Slider />
      </div>
    </>
  );
}

export default App;
