import React, { useEffect, useState } from "react";

import { HamburgerIcon } from "../helper/Icon";
import MobileHeader from "./MobileHeader";
import { headerLinks, mediaLinks } from "../helper/Helper";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;
    if (isOpen) {
      body.style.overflow = "hidden";
      html.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    };
  }, [isOpen]);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`fixed top-0 w-full z-50 ${
          isScrolled ? ` bg-[#FFDC56] ` : ``
        }`}
      >
        <div className="relative max-w-[1340px] mx-auto  sm:px-6 lg:px-8 2xl:px-10  py-5 z-10 ">
          <div className="flex justify-between items-center border-2 border-[#1A1A1A] bg-[rgba(255_255_255_0.00)] p-2 md:p-4 rounded-[20px]  navshadow">
            <a
              href="#"
              className="logo text-passion text-3xl sm:text-4xl md:text-[40px] text-white logo-shadow uppercase"
            >
              moomoo
            </a>
            <div className="items-center gap-x-4 lg:gap-x-6 xl:gap-x-8   hidden lg:flex ml-auto me-8">
              {headerLinks.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  className="text-[#393939] text-base tracking-[0.25px] transition-all duration-300 ease-in-out hover:text-white"
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="flex items-center gap-x-4">
              <a
                href={""}
                className="py-2.5 md:py-4 px-5 md:px-7 rounded-lg transition-all duration-300 ease-in-out border-2 bg-[#FDC238] border-[#1A1A1A] navshadow  hover:shadow-[4.659px_4.659px_20px_0px_#00000091] text-base text-incon lg:text-lg sm:block hidden font-bold"
              >
                BUY $MOOMOO
              </a>

              <button className="lg:hidden" onClick={toggleOpen}>
                <HamburgerIcon />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`fixed top-0 left-0 w-full h-full bg-[#FDC238] lg:bg-transparent transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <MobileHeader toggleOpen={toggleOpen} />
      </div>
    </>
  );
};

export default Header;
