import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import MinusIcon from "./../assets/img/png/minus.png";
import PlusIcon from "./../assets/img/png/plus.png";
function FaqQustion({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="border-b border-[#ABABAB]">
      <button
        onClick={toggleOpen}
        className="w-full flex justify-between items-center py-3 sm:py-4 lg:py-5 2xl:py-7 focus:outline-none"
      >
        <span className="text-lg md:text-2xl lg:text-3xl xl:text-[32px]  text-left text-[#393939]">
          {question}
        </span>
        <span className="ml-2 w-8 md:w-14 h-8 md:h-14 rounded border border-[#060205] flex flex-col justify-center items-center shadow-[5px_5px_0px_0px_#000] transition-all duration-300 ease-in-out hover:shadow-[5px_5px_20px_0px_#00000091]">
          {isOpen ? (
            <img src={MinusIcon} alt="icon" className="max-w-4 md:w-fit" />
          ) : (
            <img src={PlusIcon} alt="icon" className="max-w-4 md:w-fit" />
          )}
        </span>
      </button>
      <Transition
        show={isOpen}
        enter="transition-all duration-300 ease-in-out"
        enterFrom="max-h-0 opacity-0"
        enterTo="max-h-screen opacity-100"
        leave="transition-all duration-300 ease-in-out"
        leaveFrom="max-h-screen opacity-100"
        leaveTo="max-h-0 opacity-0"
      >
        <div className="p-4 px-0 pt-4 text-sm  sm:text-base md:text-lg text-[#393939] ">
          {answer}
        </div>
      </Transition>
    </div>
  );
}

export default FaqQustion;
