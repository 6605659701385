import React from "react";
import what from "./../assets/img/png/what.png";
import ccoin from "./../assets/img/svg/ccoin.svg";
import bom from "./../assets/img/svg/bom.svg";
import hanger from "./../assets/img/svg/hanger.svg";
function WhatMooMoo() {
  return (
    <>
      <div className="h-[5px] bg-[#360700]"></div>
      <div className="h-3 md:h-5 lg:h-7 w-full bg-[#FAA501]"></div>
      <div className="h-1 md:h-[7px] w-full bg-[#FE5500]"></div>
      <div className="h-1 md:h-[7px] w-full bg-[#FCD52D]"></div>
      <div className="h-5 lg:h-7 w-full bg-[#F8EABA]"></div>
      <div className="relative max-w-[1620px] mx-auto  lg:px-8 2xl:px-10 ">
        <div className="relative my-8 sm:py-10 lg:py-28 what-bg p-4 ">
          <div className="absolute -top-14 left-16 max-w-[72px]  hidden lg:block">
            <img src={hanger} alt="icon" className="w-full" />
          </div>{" "}
          <div className="absolute -top-14 right-16 max-w-[72px] hidden lg:block">
            <img src={hanger} alt="icon" className="w-full" />
          </div>
          <div className="flex flex-col-reverse lg:flex-row gap-5 pt-10 2xl:pt-24">
            <div className="relative  max-w-[580px] flex flex-col items-center mx-auto lg:mx-0">
              <h1 className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl 2xl:text-[100px] font-bold text-passion heading-shadow text-white  capitalize  absolute rotate-[-10deg] ml-10 top-4 lg:block hidden">
                What is{" "}
              </h1>
              <img src={what} alt="icon" className="" />
            </div>
            <div className="lg:max-w-[500px] xl:max-w-[800px] 2xl:max-w-[870px] relative">
              {" "}
              <div className="absolute right-2 top-0 xl:top-6 max-w-[50px]  ">
                <img src={bom} alt="icon" className="w-full" />
              </div>
              <h1 className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl 2xl:text-[100px] font-bold text-passion heading-shadow text-white uppercase lg:hidden">
                What is{" "}
              </h1>
              <h1 className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl 2xl:text-[100px] font-bold text-passion heading-shadow text-[#FDC238]  uppercase inline relative">
                {" "}
                <div className="absolute -right-20 lg:-right-32 -top-8 lg:-top-14 max-w-[70px] lg:max-w-[117px]  ">
                  <img src={ccoin} alt="icon" className="w-full" />
                </div>
                $moomoo ?
              </h1>
              <p className="my-5 xl:my-8 md:pb-2 text-[#393939] text-base sm:text-lg lg:text-xl xl:text-[24px] leading-[160%] md:!tracking-[1px]">
                Moomoo is the undisputed maestro of the 'Cat-puter Keyboard
                Symphony,' composing melodic masterpieces with his paw-tapping
                prowess. His keyboard serenades range from soothing melodies to
                chaotic compositions, leaving his human companions torn between
                frustration and admiration for his artistic endeavors.
              </p>
              <div className="sm:pt-6 pt-3 2xl:pt-10 flex">
                {" "}
                <a
                  href={""}
                  className="py-2.5 md:py-4 px-5 md:px-7 rounded-lg transition-all duration-300 ease-in-out border-2 bg-[#FDC238] border-[#1A1A1A] navshadow    text-base text-incon lg:text-2xl xl:text-3xl  font-bold rotate-[-1.5deg] hover:shadow-[4.659px_4.659px_20px_0px_#00000091] "
                >
                  BUY $MOOMOO
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatMooMoo;
