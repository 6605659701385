import React from "react";
import { useState } from "react";
import chart from "./../assets/img/svg/chart-up.svg";
import DonateChart from "./DonateChart";
import tokenLeft from "./../assets/img/png/token-left.png";
import tokenLeft2 from "./../assets/img/png/token-left2.png";
import grass from "./../assets/img/png/grass.png";
import cloud from "./../assets/img/png/cloud.png";
import tokenright from "./../assets/img/png/token-right.png";
import { CopyIcon } from "../helper/Icon";
function Tokenomics() {
  const [copied, setCopied] = useState(false);
  const textToCopy = "m6gG3z28NjKQn1GyEUjFEKTQnFmCeP2RLFYRphTJoSH";

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <>
      <div className="h-[5px] bg-[#360700]"></div>
      <div className="h-3 md:h-5 lg:h-7 w-full bg-[#FAA501]"></div>
      <div className="h-1 md:h-[7px] w-full bg-[#FE5500]"></div>
      <div className="h-1 md:h-[7px] w-full bg-[#FCD52D]"></div>
      <div className="h-5 lg:h-7 w-full bg-[#F8EABA]"></div>
      <div
        id="Tokonomics"
        className=" relative  bg-[#FDC238] py-16 overflow-hidden "
      >
        <div className="absolute top-3 left-0 max-w-[150px] sm:max-w-[200px] lg:max-w-[300px] xl:max-w-[400px]">
          <img src={tokenLeft} alt="icon" className="w-full" />
        </div>
        <div className="absolute -top-3 md:-top-10 right-0 max-w-[150px] sm:max-w-[200px] lg:max-w-[300px] xl:max-w-[400px]">
          <img src={cloud} alt="icon" className="w-full" />
        </div>
        <div className="absolute bottom-0 left-0 max-w-[150px] sm:max-w-[200px] lg:max-w-[250px] xl:max-w-[350px] 2xl:max-w-[440px]">
          <img src={tokenLeft2} alt="icon" className="w-full" />
        </div>{" "}
        <div className="absolute -bottom-4 lg:-bottom-6 left-40 lg:left-80 max-w-[150px] sm:max-w-[200px] lg:max-w-[250px] xl:max-w-[350px] 2xl:max-w-[440px]">
          <img src={grass} alt="icon" className="w-full" />
        </div>
        <div className="absolute -bottom-2  right-0 max-w-[250px] sm:max-w-[250px] lg:max-w-[300px] xl:max-w-[600px]">
          <img src={tokenright} alt="icon" className="w-full" />
        </div>
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8 2xl:px-10 relative z-10 pt-10 lg:pt-28">
          <div className="flex flex-col md:flex-row justify-center lg:justify-between gap-10 xl:gap-20 ">
            <div className="relative max-w-[300px] lg:max-w-[500px] mx-auto md:mx-0 flex flex-col justify-center items-center">
              <div className="">
                <img src={chart} alt="img" className="w-100" />
              </div>

              <div className="absolute  ">
                <DonateChart />
              </div>
            </div>
            <div className="md:max-w-[577px] ">
              <h1 className="text-4xl md:text-5xl lg:text-6xl 2xl:text-[80px]  text-[#303030]  capitalize">
                Tokenomics
              </h1>{" "}
              <div className="my-5 sm:my-7 lg:my-8">
                <p className="text-base sm:text-lg text-[#393939]">
                  {" "}
                  Total Supply
                </p>
                <p className="text-xl sm:text-2xl lg:text-3xl xl:text-[32px] text-[#393939]">
                  69,000,000,000,000
                </p>
              </div>
              <div className="flex flex-col gap-3 sm:gap-5 xl:gap-6">
                <div className="flex items-center gap-2 sm:gap-3 xl:gap-4">
                  <div className="border-2 lg:border-[3px] xl:border-[7px] w-7 md:w-10 h-7 md:h-10 border-white rounded-full bg-[#EFB970]"></div>
                  <p className="text-base sm:text-lg xl:text-[22px] text-[#393939]">
                    92% Community
                  </p>
                </div>{" "}
                <div className="flex items-center gap-2 sm:gap-3 xl:gap-4">
                  <div className="border-2 lg:border-[3px] xl:border-[7px] w-7 md:w-10 h-7 md:h-10 border-white rounded-full bg-[#5262F3]"></div>
                  <p className="text-base sm:text-lg xl:text-[22px] text-[#393939]">
                    8% CEX Listing
                  </p>
                </div>
              </div>
              <div className="flex mt-6 sm:mt-8 xl:mt-12">
                <a
                  href={""}
                  className="py-2.5 md:py-4 px-5 md:px-7 rounded-lg transition-all duration-300 ease-in-out border-2 bg-[#FDC238] border-[#1A1A1A] navshadow    text-base text-incon lg:text-2xl xl:text-3xl  font-bold rotate-[-1.5deg] hover:shadow-[4.659px_4.659px_20px_0px_#00000091] "
                >
                  BUY $MOOMOO
                </a>
              </div>
            </div>
          </div>
          <div className="my-16 max-w-[750px] xl:max-w-[881px] ml-auto w-full lg:pt-10 pb-20 lg:pb-0">
            <p className="text-base sm:text-lg xl:text-[22px] text-[#393939] mb-4 lg:mb-5 xl:mb-6">
              Contract Address
            </p>
            <div className="border-[3px] border-black bg-[#FAA501] p-2 rounded-lg ">
              <div className="border border-black rounded-md py-4 lg:py-6 px-2 bg-white flex items-center whitespace-nowrap justify-between">
                <p className="text-base sm:text-lg xl:text-[22px] text-[#393939] text-ellipsis overflow-hidden  truncat">
                  AtKjNndzNBJAo4Z7o23hTMcucBwJX94y94E2Aj82YWux
                </p>{" "}
                <button className=" h-8 " onClick={handleCopy}>
                  {copied ? "Copied!" : <CopyIcon />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[5px] bg-[#360700]"></div>
      <div className="h-3 md:h-5 lg:h-7 w-full bg-[#FAA501]"></div>
      <div className="h-1 md:h-[7px] w-full bg-[#FE5500]"></div>
      <div className="h-1 md:h-[7px] w-full bg-[#FCD52D]"></div>
      <div className="h-5 lg:h-7 w-full bg-[#F8EABA]"></div>
    </>
  );
}

export default Tokenomics;
