// src/DonateChart.js
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import chartCenter from "./../assets/img/png/chart-center.png";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonateChart = () => {
  const data = {
    labels: ["Community", "CEX Listing"],
    datasets: [
      {
        data: [92, 8],
        backgroundColor: ["#EFB970", "#5262F3"],
        hoverBackgroundColor: ["#EFB970", "#5262F3"],
        spacing: 6,
        borderColor: "black",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
    },
  };

  return (
    <div className="flex justify-center items-center my-20  ">
      <div className=" w-[240px] lg:w-[380px] h-[240px] lg:h-[380px] rounded-full bg-white relative flex flex-col justify-center items-center ">
        <Doughnut data={data} options={options} className="  " />
        <div className="max-w-[170px] lg:max-w-[260px] absolute border-[20px] lg:border-[40px] rounded-full border-white outline-black outline">
          <img src={chartCenter} alt="img" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default DonateChart;
