import React from "react";
import step1 from "./../assets/img/png/step-1.png";
import step2 from "./../assets/img/png/step-2.png";
import step3 from "./../assets/img/png/step-3.png";
import step4 from "./../assets/img/png/step-4.png";
function HowTobuy() {
  return (
    <>
      <div id="HowtoBuy" className="buy-bg ">
        <div className="max-w-[1300px] mx-auto px-4 sm:px-6 lg:px-8 2xl:px-10 relative z-10 py-16 lg:py-20">
          <h1 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-[80px]  text-[#303030] text-center capitalize">
            How To Buy
          </h1>
          <div className="flex-col mt-8 lg:mt-10 hidden sm:flex">
            <div className="w-full relative">
              <img
                src={step1}
                alt="icon"
                className="w-full  min-h-[130px] sm:min-h-[200px] md:min-h-[260px] lg:min-h-[310px]"
              />{" "}
              <h2 className="absolute top-4 sm:top-0 md:top-4 lg:top-0 xl:-top-5   left-[20%] text-[35px] sm:text-[55px] md:text-[60px] lg:text-[90px] xl:text-[140px]  text-[#1A1A1A] opacity-20  ">
                1{" "}
              </h2>
              <div className="absolute left-[35%] top-8 md:top-10  md:py-4">
                <h4 className=" text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-[#303030]">
                  Create a wallet
                </h4>
                <p className="text-[12px] sm:text-sm lg:text-base xl:text-lg text-[#393939] leading-[150%] max-w-[96%] lg:max-w-[78%] pt-2 lg:pt-4 xl:pt-5">
                  Download a Solana wallet. We recommend Phantom or Solflare.
                  Thesecan be downloaded from the appstore or as chrome
                  extensions desktop users. for
                </p>
              </div>
            </div>{" "}
            <div className="w-full relative -mt-0.5">
              <img
                src={step2}
                alt="icon"
                className="w-full min-h-[130px]  sm:min-h-[200px] md:min-h-[300px] xl:min-h-[401px]"
              />{" "}
              <h2 className="absolute  top-4 md:top-7 lg:top-10 xl:-top-5  left-[20%]  xl:left-[18%] text-[35px] sm:text-[55px] md:text-[60px] lg:text-[90px] xl:text-[140px]  text-[#1A1A1A] opacity-20  ">
                2
              </h2>
              <div className="absolute left-[35%] top-10 md:top-14 lg:top-16  xl:top-20 md:py-4 xl:py-7">
                <h4 className=" text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-[#303030]">
                  ADD SOLONA
                </h4>
                <p className="text-[12px] sm:text-sm lg:text-base xl:text-lg text-[#393939] leading-[150%] max-w-[96%] lg:max-w-[78%] pt-2 lg:pt-4 xl:pt-5">
                  Purchase Solana through the Phantom wallet or alternatively
                  send Solana to your new wallet from an exchange.
                </p>
              </div>
            </div>{" "}
            <div className="w-full relative ml-4 -mt-0.5">
              <img
                src={step3}
                alt="icon"
                className="w-full min-h-[130px] sm:min-h-[200px] md:min-h-[280px] lg:min-h-[340px] "
              />
              <h2 className="absolute  top-4 md:top-7 lg:top-10 xl:-top-0  left-[20%]  xl:left-[18%] text-[35px] sm:text-[55px] md:text-[60px] lg:text-[90px] xl:text-[140px]  text-[#1A1A1A] opacity-20  ">
                3
              </h2>
              <div className="absolute left-[35%] top-10 md:top-14 lg:top-16  xl:top-20 md:py-4 xl:py-7">
                <h4 className=" text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-[#303030]">
                  SWAP SOL FOR MOOMOO
                </h4>
                <p className="text-[12px] sm:text-sm lg:text-base xl:text-lg text-[#393939] leading-[150%] max-w-[96%] lg:max-w-[88%] pt-2 lg:pt-4 xl:pt-5">
                  Go to this link:{" "}
                  <a
                    target="_blank"
                    href="https://jup.ag/swap/SOL-MOOMOO"
                    className="no-underline text-[#2758B6]  "
                  >
                    https://jup.ag/swap/SOL-MOOMO0
                  </a>
                  and connect your wallet. Convert SOL to $MOOMOO.
                </p>
              </div>
            </div>{" "}
            <div className="w-full relative ml-3">
              <img
                src={step4}
                alt="icon"
                className="w-full min-h-[130px] sm:min-h-[200px] lg:min-h-[380px]"
              />{" "}
              <h2 className="absolute  top-4 md:top-7 lg:top-10 xl:-top-0  left-[20%]  xl:left-[18%] text-[35px] sm:text-[55px] md:text-[60px] lg:text-[90px] xl:text-[140px]  text-[#1A1A1A] opacity-20  ">
                4
              </h2>
              <div className="absolute left-[35%] top-14 lg:top-16  xl:top-20 md:py-4 xl:py-7">
                <h4 className=" text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-[#303030]">
                  YOU’RE ALL SET
                </h4>
                <p className="text-[12px] sm:text-sm lg:text-base xl:text-lg text-[#393939] leading-[150%] max-w-[82%] pt-2 lg:pt-4 xl:pt-5">
                  Your MOOMOO tokens should now be in your wallet. Welcome to
                  the $MOOMOO-fund!
                </p>
              </div>
            </div>
          </div>
          <div className="my-10 sm:hidden flex flex-col gap-6">
            <div className="p-4 border border-black rounded-lg bg-[#FDC238] ">
              <h2 className="text-[35px] text-[#1A1A1A] opacity-80   ">1</h2>
              <div className="mt-4">
                <h4 className="text-2xl text-[#303030]">Create a wallet</h4>
                <p className="text-sm text-[#393939] leading-[150%]  pt-4 ">
                  Download a Solana wallet. We recommend Phantom or Solflare.
                  Thesecan be downloaded from the appstore or as chrome
                  extensions desktop users. for
                </p>
              </div>
            </div>{" "}
            <div className="p-4 border border-black rounded-lg bg-[#FB923A] ">
              <h2 className="text-[35px] text-[#1A1A1A] opacity-80   ">2</h2>
              <div className="mt-4">
                <h4 className="text-2xl text-[#303030]">ADD SOLONA</h4>
                <p className="text-sm text-[#393939] leading-[150%]  pt-4 ">
                  Purchase Solana through the Phantom wallet or alternatively
                  send Solana to your new wallet from an exchange.
                </p>
              </div>
            </div>{" "}
            <div className="p-4 border border-black rounded-lg bg-[#FF6D46] ">
              <h2 className="text-[35px] text-[#1A1A1A] opacity-80   ">3</h2>
              <div className="mt-4">
                <h4 className="text-2xl text-[#303030]">SWAP SOL FOR MOOMOO</h4>
                <p className="text-sm text-[#393939] leading-[150%]  pt-4 ">
                  Go to this link: https://jup.ag/swap/SOL-MOOMOO and connect
                  your wallet. Convert SOL to $MOOMOO.
                </p>
              </div>
            </div>{" "}
            <div className="p-4 border border-black rounded-lg   ">
              <h2 className="text-[35px] text-[#1A1A1A] opacity-80   ">4</h2>
              <div className="mt-4">
                <h4 className="text-2xl text-[#303030]">YOU’RE ALL SET</h4>
                <p className="text-sm text-[#393939] leading-[150%]  pt-4 ">
                  Your MOOMOO tokens should now be in your wallet. Welcome to
                  the $MOOMOO-fund!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowTobuy;
